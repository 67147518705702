import {makeStyles, Theme} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import React from "react";

type MarkerOnOffSwitchProps = {
    checked: boolean
    onChange?(event: object): void
    name: string
    disabled: boolean | undefined
    fontSize: number
    onClick?(event: object): void
}
export const MarkerOnOffSwitch = (props: MarkerOnOffSwitchProps) => {


    const fontSize = props.fontSize || 13

    const useStyles = makeStyles((theme: Theme) => ({
            root: {
                width: fontSize * 1.75,
                height: fontSize,
                padding: 0,
            },
            switchBase: {
                padding: fontSize / 7,
                color: theme.palette.grey[500],
                "&$checked": {
                    transform: `translateX(${fontSize * 0.75}px)`,
                    color: theme.palette.common.white,
                    "& + $track": {
                        opacity: 1,
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                    },
                },
            },
            thumb: {
                width: fontSize * 0.75,
                height: fontSize * 0.75,
                boxShadow: "none",
            },
            track: {
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: fontSize / 2,
                opacity: 1,
                backgroundColor: theme.palette.common.white,
            },
            checked: {},
            disabled: {
                "&$disabled": {
                    "& + $track": {
                        opacity: 0.5,
                    },
                },
            },
        })
    )

    const classes = useStyles()

    return <Switch
        {...props}
        classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
            disabled: classes.disabled,
        }}/>
}
